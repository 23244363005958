<template>
  <div class="about pt-4">
    <h5 class="letter-spacing-2">ABOUT US</h5>
    <div class="container">
      <div class="row m-2">
        <div class="col-4 text-start">
          <div class="info-box">
            We have a specific vision, a specific goal to unite Professionals, Landlords and Tenants in a centralised
            platform allowing managing and searching for the properties with ease.
          </div>
        </div>
      </div>
      <div class="row m-2">
        <div class="col-4">
          <font-awesome-icon icon="share" class="arrow-right" style="color: red;"/>
        </div>
        <div class="col-4 text-start">
          <div class="info-box">
            We are working on a few exciting features that are not yet on the market and we will strive to bring
            them on the table, we also want to introduce messaging and booking system which we are working on right now.
            We want you to be recognised, we want you to be the owner of your listings.
          </div>
        </div>
      </div>
      <div class="row m-2">
        <div class="col-4 offset-4">
          <font-awesome-icon icon="share" class="arrow-right" style="color: blue;"/>
        </div>
        <div class="col-4 text-start">
          <div class="info-box">
            Fed up searching for multiple hours for that one specific property you are interested in? We were!
            This is why we have created advanced filtering system that will allow you to broaden or restrict
            search to very detail specific features that are of your interest.
          </div>
        </div>
      </div>
      <div class="row m-2">
        <div class="col-4 offset-4 text-start">
          <div class="info-box">
            Lesser chance to encounter outdated listings, each listing last from 7 days up to 3 months, users are
            encouraged to set their listings as 'completed' once their listings are finished. Automatic system also
            changes status of listings once they expire. Relief!
          </div>
        </div>
        <div class="col-4">
          <font-awesome-icon icon="share" class="arrow-left" style="color: red;"/>
        </div>
      </div>
      <div class="row m-2">
        <div class="col-4 text-start">
          <div class="info-box">
            We hope you will enjoy using our platform, however if you encounter any issues or you would like to provide
            feedback that could lead to a better user experience, please feel free to
            <router-link class="text-decoration-none" to="/contact">contact us</router-link>.
          </div>
        </div>
        <div class="col-4">
          <font-awesome-icon icon="share" class="arrow-left" style="color: blue;"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  mounted() {
    // Toggle background to be invisible (we want video parallax)
    let main = document.getElementById('mainContent');
    if (main) {
      main.classList.add('main-content-transparent');
      if (main.classList.contains('main-content')) {
        main.classList.remove('main-content');
      }
    }
  },
  beforeDestroy() {
    // Toggle back the background to be visible
    let main = document.getElementById('mainContent');
    if (main) {
      main.classList.add('main-content');
      if (main.classList.contains('main-content-transparent')) {
        main.classList.remove('main-content-transparent');
      }
    }
  }
}
</script>

<style lang="scss">

.about h5 {
  color: white;
  text-shadow: 3px 2px #3c3c3c;
}

.arrow-right {
  transform: rotate(180deg) scaleX(-1);
  font-size: 40px;
}

.arrow-left {
  transform: rotate(180deg);
  font-size: 40px;
}

.info-box {
  background-color: rgba(255, 255, 255, 0.8);
  margin: 2px;
  border-radius: 10px;
  border-left: 1px solid #9e9e9e;
  border-right: 1px solid #9e9e9e;
  box-shadow: 2px 0.5px 2px #9e9e9e;
  padding: 10px;
}

</style>